<template>
<div class="commercialTenant">
  <el-form :inline="true">
    <el-form-item>
      <myInput v-model="form.merchantId" placeholder="输入商户编号">
        <template slot="pre">
          <span>商户编号:</span>
        </template>
      </myInput>
    </el-form-item>
    <el-form-item>
      <myInput v-model="form.storePayNo" placeholder="输入收款码编号">
        <template slot="pre">
          <span>收款码编号:</span>
        </template>
      </myInput>
    </el-form-item>
    <el-form-item>
      <myInput v-model="form.storeName" placeholder="输入店铺名称/摊位名称">
        <template slot="pre">
          <span>店铺/摊位名称:</span>
        </template>
      </myInput>
    </el-form-item>
    <el-form-item>
      <myButton icon title="搜索" @myclick="currentChange(1)">
        <template slot="image">
          <img src="../../assets/image/btnicon/search.png" style="width:14px;height:14px" alt="">
        </template>
      </myButton>
    </el-form-item>
    <el-form-item>
      <myButton title="新增" @myclick="shopVisible = true">
        <template slot="image">
          <img src="../../assets/image/btnicon/add.png" alt="">
        </template>
      </myButton>
    </el-form-item>
  </el-form>
  <el-table :data="tableData" :span-method="objectSpanMethod" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
    <el-table-column label="商户编号" prop="mktMrchId"></el-table-column>
    <el-table-column label="商户姓名" prop="mktMrchNm"></el-table-column>
    <el-table-column label="收款码编号" prop="storePayNo"></el-table-column>
    <el-table-column label="店铺/摊位名称" prop="storeName"></el-table-column>
    <el-table-column label="店铺/摊位编号" prop="storeNo"></el-table-column>
    <el-table-column label="店员编号" prop="storeNumber"></el-table-column>
    <el-table-column label="收款播报设备" prop="storeImeiTotal"></el-table-column>
    <el-table-column label="C-1" prop="storeC1"></el-table-column>
    <el-table-column label="C-2" prop="storeC2"></el-table-column>
    <el-table-column label="收款方" prop="storePayee"></el-table-column>
    <el-table-column label="收款码">
      <template slot-scope="scope">
        <div class="slot-box">
          <pop tips="查看收款码" @myclick="viewCode(scope.row)">
            <img class="icon" src="../../assets/image/icon/codeDown.png" alt="">
          </pop>
        </div>
        <!-- <div :id="`qrcode${scope.row.storeId}`">
          {{qrcode(scope.row, scope.row.storeId)}}
        </div> -->
      </template>
    </el-table-column>
    <el-table-column label="操作" width="180px">
      <template slot-scope="scope">
        <div class="slot-box">
          <pop tips="编辑" @myclick="edit(scope.row)">
            <img class="icon" src="../../assets/image/icon/edit.png" alt="">
          </pop>
          <pop tips="绑定设备" @myclick="binding(scope.row)">
            <img class="icon" src="../../assets/image/icon/bangdingAudio.png" />
          </pop>
          <pop tips="添加店铺" @myclick="addShop(scope.row)">
            <img class="icon" src="../../assets/image/icon/addShop.png" />
          </pop>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="publicPage">
    <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="form.pageSize" :current-page="form.page" @current-change="currentChange"></el-pagination>
  </div>
  <el-dialog title="新增/编辑收款播报器" :visible.sync="dialogVisible" width="880px" @close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
      <el-table :data="ruleForm.data" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
        <el-table-column label="设备名称" prop="deviceName">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].deviceName`" :rules="{ required: true, message: '请输入设备名称', trigger: 'blur'}">
              <el-input v-model="scope.row.deviceName" placeholder="请输入设备名称"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="设备IMEI码" prop="imei">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].imei`" :rules="{ required: true, message: '请输入设备IMEI码', trigger: 'blur'}">
              <el-input v-model="scope.row.imei" placeholder="请输入设备IMEI码"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="设备Key" prop="productKey">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].productKey`" :rules="{ required: true, message: '请输入设备Key', trigger: 'blur'}">
              <el-input v-model="scope.row.productKey" style="width: 100%" placeholder="请输入设备Key"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="设备Secret" prop="deviceSecret">
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].deviceSecret`" :rules="{ required: true, message: '请输入设备Secret', trigger: 'blur'}">
              <el-input v-model="scope.row.deviceSecret" placeholder="请输入设备Secret"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <myButton @myclick="delInspection(scope.$index)">删除</myButton>
            <div class="" style="height: 20px;"></div>
          </template>
        </el-table-column>
      </el-table>
      <div style="marginTop: 15px;">
        <myButton @myclick="addInspection">添加</myButton>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <myButton @myclick="dialogVisible = false" right>取消</myButton>
      <myButton @myclick="confirm">确定</myButton>
    </span>
  </el-dialog>
  <el-dialog title="新增/编辑店铺" :visible.sync="shopVisible" width="880px" @close="shopClose">
    <el-form :model="shopRuleForm" :rules="shopRules" ref="shopRuleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="商户编号" prop="merchantId">
        <el-select v-model="shopRuleForm.merchantId" :disabled="isAdd" @change="changeMerchant" class="public-input">
          <el-option :label="item.mktMrchNm" :value="item.id" v-for="item in hsbMerchantList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商户姓名">
        <el-input v-model="shopRuleForm.ctcpsnNm" class="public-input" :disabled="true" placeholder="输入商户姓名"></el-input>
      </el-form-item>
      <el-form-item label="店铺/摊位名称" prop="storeName">
        <el-input v-model="shopRuleForm.storeName" class="public-input" placeholder="输入店铺/摊位名称"></el-input>
      </el-form-item>
      <el-form-item label="店铺/摊位编号" prop="storeNo">
        <el-input v-model="shopRuleForm.storeNo" class="public-input" placeholder="输入店铺/摊位编号"></el-input>
      </el-form-item>
      <el-form-item label="店员编号" prop="storeNumber">
        <el-input v-model="shopRuleForm.storeNumber" class="public-input" placeholder="输入店员编号"></el-input>
      </el-form-item>
      <el-form-item label="收款方" prop="storePayee">
        <el-input v-model="shopRuleForm.storePayee" :disabled="true" class="public-input" placeholder="输入收款方"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <myButton @myclick="shopVisible = false" right>取消</myButton>
      <myButton @myclick="shopConfirm">确定</myButton>
    </span>
  </el-dialog>
  <el-dialog title="查看收款码" :visible.sync="codeVisible" width="880px">
    <div class="qrBox">
      <div id="qrcode">
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <myButton @myclick="codeVisible = false" right>取消</myButton>
      <myButton @myclick="codeExport">下载</myButton>
    </span>
  </el-dialog>
</div>
</template>
<script>
import QRCode from 'qrcodejs2';

export default {
  name: 'list',
  data() {
    return {
      form: {
        page: 1,
        pageSize: 10,
        storeName: '',
        merchantId: '',
        storePayNo: ''
      },
      total: 0,
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        storeId: '',
        data: []
      },
      rules: {
        imei: [{
          required: true,
          message: '请输入设备IMEI码',
          trigger: 'click'
        }]
      },
      shopVisible: false,
      shopRuleForm: {
        merchantId: '',
        ctcpsnNm: '',
        storeName: '',
        storeNo: '',
        storeNumber: '',
        storePayee: '重庆牧予商业管理有限公司'
      },
      shopRules: {
        merchantId: [{
          required: true,
          message: '请选择商户编号',
          trigger: 'change'
        }],
        storeName: [{
          required: true,
          message: '请输入店铺/摊位名称',
          trigger: 'blur'
        }],
        storeNo: [{
          required: true,
          message: '请输入店铺/摊位编号',
          trigger: 'blur'
        }],
        storeNumber: [{
          required: true,
          message: '请输入店员编号',
          trigger: 'blur'
        }],
        storePayee: [{
          required: true,
          message: '请输入收款方',
          trigger: 'blur'
        }]
      },
      hsbMerchantList: [],
      isAdd: false,
      codeVisible: false,
      codeRow: null
    };
  },
  created() {
    this.getHsbMerchantList();
    this.getList();
  },
  methods: {
    viewCode(row) {
      this.codeRow = row;
      this.codeVisible = true;
      this.$nextTick(() => {
        this.qrcode(row);
      });

    },
    // 生成二维码
    qrcode(row) {
      if (row.storeImeiUrl) {
        this.$nextTick(() => {
          if (document.getElementById('qrcode')) {
            document.getElementById('qrcode').innerHTML = '';
            new QRCode('qrcode', {
              text: row.storeImeiUrl,
              width: 200,
              height: 200,
              correctLevel: QRCode.CorrectLevel.H
            });
          }
        });
        this.drawText(row);
      }
    },
    // 二维码添加文字
    drawText(row) {
      let image = new Image();
      image.src = require('../../assets/image/codelogo.png');
      this.$nextTick(() => {
        let canvas = document.querySelector('#qrcode canvas');
        if (!canvas.getContext) {
          return;
        }
        let ctx = canvas.getContext('2d');
        this.resize('#qrcode', '200', '250'); // 重置画布大小
        ctx.beginPath();
        ctx.fillStyle = 'rgb(255,255,255)'; // 分配颜色
        // ctx.arc(60, 60, 30,0,Math.PI * 2);     
        ctx.fillStyle = '#00923F'; // 分配颜色
        ctx.font = 'bold 12px Arial '; // 文本大小, 字体
        ctx.fillText(row.mktMrchNm + '(' + row.storeNumber + ')', 20, 220); // 绘制文本(text, x, y)
        ctx.fillText(row.storeName,30,240)
          image.onload = () => {
          ctx.drawImage(image, 67, 65, 60, 60);
        };
      });
    },
    codeExport() {
      let dataUrl = document.querySelector('#qrcode').querySelector('img').src;
      let link = document.createElement('a');
      link.download = `${this.codeRow.storePayNo}.png`;
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    resize(id, w, h) {
      let canvas = document.querySelector(`${id} canvas`);
      let ctx = canvas.getContext('2d');
      let nc = document.createElement('canvas');
      nc.width = canvas.width;
      nc.height = canvas.height;
      nc.getContext('2d').drawImage(canvas, 0, 0);
      canvas.width = w;
      canvas.height = h;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0,0,w,h);
      ctx.drawImage(nc, 0, 0);
    },
    objectSpanMethod({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if ([0, 1].indexOf(columnIndex) != -1) {
        if (row.mergeRow) {
          return {
            rowspan: row.mergeRow,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    addShop(row) {
      this.isAdd = true;
      this.shopRuleForm.merchantId = row.merchantId;
      this.changeMerchant(row.merchantId);
      this.shopVisible = true;
    },
    edit(row) {
      let str = row;
      this.shopRuleForm = {
        ...str
      };
      this.shopVisible = true;
      this.changeMerchant(str.merchantId);
    },
    changeMerchant(id) {
      for (let i in this.hsbMerchantList) {
        if (this.hsbMerchantList[i].id == id) {
          this.shopRuleForm.ctcpsnNm = this.hsbMerchantList[i].ctcpsnNm;
        }
      }
    },
    getHsbMerchantList() {
      this.$request.HttpGet('/admin/hsbMerchant/list', {
        page: 1,
        pageSize: 1000,
        enterprise_merchant_status: 1
      }).then(res => {
        this.hsbMerchantList = res.data.list;
      });
    },
    shopClose() {
      this.isAdd = false;
      this.shopRuleForm = {
        merchantId: '',
        ctcpsnNm: '',
        storeName: '',
        storeNo: '',
        storeNumber: '',
        storePayee: '重庆牧予商业管理有限公司'
      };
    },
    shopConfirm() {
      this.$refs['shopRuleForm'].validate((valid) => {
        if (valid) {
          let url = '/admin/hsbMerchantStore/create';
          if (this.shopRuleForm.storeId) {
            url = '/admin/hsbMerchantStore/update';
          }
          this.$request.HttpPost(url, this.shopRuleForm).then(res => {
            this.$plugin.notifySuccess(res.msg);
            this.shopVisible = false;
            this.getList();
          });
        }
      });
    },
    getList() {
      this.$request.HttpGet('/admin/hsbMerchantStore/list', this.form).then(res => {
        if (res.data.list) {
          let arr = [];
          for (let i in res.data.list) {
            if (res.data.list[i].hsbMerchantStoreList) {
              res.data.list[i].hsbMerchantStoreList.forEach((item, index) => {
                arr.push({
                  ...res.data.list[i],
                  ...item,
                  mergeRow: index == 0 ? res.data.list[i].hsbMerchantStoreList.length : '',
                  mktMrchNm: res.data.list[i].mktMrchNm,
                  mktMrchId: res.data.list[i].mktMrchId
                });
              });
            }
          }
          this.tableData = arr;
        }
        this.total = res.data.total;
      });
    },
    currentChange(page) {
      this.form.page = page;
      this.getList();
    },
    binding(row) {
      this.$request.HttpGet('/admin/hsbMerchantStoreImei/list', {
        storeId: row.storeId,
        page: 1,
        pageSize: 10000
      }).then(res => {
        this.ruleForm.storeId = row.storeId;
        this.ruleForm.data = res.data.list || [];
        this.dialogVisible = true;
      });
    },
    addInspection() {
      this.ruleForm.data.push({
        imei: '',
        productKey: '',
        deviceSecret: '',
        deviceName: ''
      });
    },
    delInspection(index) {
      this.ruleForm.data.splice(index, 1);
    },
    handleClose() {
      this.ruleForm = {
        storeId: '',
        data: []
      };
    },
    confirm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$request.HttpPost('/admin/hsbMerchantStoreImei/create', this.ruleForm).then(res => {
            this.$plugin.notifySuccess(res.msg);
            this.dialogVisible = false;
            this.getList();
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.boxList {
    .list {
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        .el-button {
            margin-left: 10px;
        }
    }
}
.imgBox {
    width: 200px;
    display: block;
    margin-bottom: 10px;
}
.qrBox {
    display: flex;
    justify-content: center;
}
</style>
